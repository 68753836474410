<template>
  <v-card max-width="600" class="mx-auto py-1" :loading="loading">
    <v-treeview
      activatable
      :active.sync="active"
      :open.sync="open"
      hoverable
      open-on-click
      :items="items"
      :load-children="fetchDocuments"
      return-object
      ref="treeview"
    >
      <template v-slot:prepend="{ open, item }">
        <DocumentItemIcon :value="item" :open="open" />
      </template>
      <template v-slot:label="{ item }">
        <DocumentItem :value="item" />
      </template>
      <template v-slot:append="{ item, open }">
        <DocumentItemAppend :value="item" :open="open" />
      </template>
    </v-treeview>
    <router-view></router-view>
  </v-card>
</template>

<script>
import DocumentItem from './Components/DocumentItem.vue';
import DocumentItemIcon from './Components/DocumentItemIcon.vue';
import DocumentItemAppend from './Components/DocumentItemAppend.vue';

export default {
  components: { DocumentItem, DocumentItemIcon, DocumentItemAppend },
  props: ['catId'],
  data() {
    return {
      categories: [],
      items: [],
      active: [],
      open: [],
      documents: [],
      loading: false,
    };
  },
  computed: {
    filter() {
      return (item) => item.documentsCount > 0;
    },
  },
  watch: {
    active(arr) {
      if (arr.length) {
        const item = arr[0];
        this.$router.push(`/documents/cat/${this.catId}/document/${item.id}`);
        this.active = [];
      }
    },
    open(arr) {
      if (!this.loading && !this.search) {
        const query = arr
          .map((el) => el.id)
          .sort((a, b) => a - b)
          .join('/');
        this.$router.push(`/documents/cat/${query}`);
      }
    },
  },
  methods: {
    async fetchDocuments(item) {
      item.children.push(
        ...item.categories.map((el) => ({
          ...el,
          children: [],
        }))
      );
      if (item.documentsCount > 0) {
        const cat = await this.apiGet({
          resource: 'document/category',
          id: item.id,
        });
        item.children.push(
          ...cat.documents.map((el) => ({
            ...el,
            disabled: !el.available,
          }))
        );
      }
      return item;
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  async created() {
    this.loading = true;
    this.categories = await this.apiList({
      resource: 'document/category',
      query: ``,
    });
    this.items = this.categories.map((el) => ({
      ...el,
      children: [],
    }));

    if (this.catId) {
      const catIds = this.catId.split('/').sort((a, b) => a - b);
      const cats = [];
      for (let i = 0; i < catIds.length; i++) {
        if (i > 0) {
          await this.timeout(200);
        }

        const id = catIds[i];
        const cat = await this.apiGet({
          resource: 'document/category',
          id: id,
        });
        cats.push(cat);
        this.open = cats;
      }
      this.open = cats;
      await this.timeout(200);
    }
    this.loading = false;
  },
};
</script>
